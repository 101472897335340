import { useAppSelector } from "../../../app/hooks";
import { Link } from "react-router-dom";
import Crypto from "./Crypto";
import P2P from "./P2P";
import Swap from "./Swap";
import Transfer from "./Transfer";
import Stakes from "./Stakes";
import TransactionOption from "./TransactionOptions";

const TransactionHistory = () => {
  const { transactionTab } = useAppSelector((state) => state.wallet);

  return (
    <div className="px-3 py-4 bg-white rounded-lg md:py-8 dark:bg-clrDarkBg">
      <div className=" md:pl-4">
        <h2 className="mb-5 text-xs font-semibold md:mb-6 md:text-sm text-clrTextGray dark:text-white">
          Transaction History
        </h2>
      </div>
      <section className="pb-6 mx-auto">
        <div className="bg-white rounded-lg md:px-4 dark:bg-clrDarkBg md:py-5">
          <TransactionOption />
          <Crypto showFilter={false} show={transactionTab === 1} />
          <P2P showFilter={false} show={transactionTab === 2} />
          <Swap showFilter={false} show={transactionTab === 3} />
          <Transfer showFilter={false} show={transactionTab === 4} />
          <Stakes showFilter={false} show={transactionTab === 5} />
        </div>
      </section>
      <div className="flex items-center justify-center mt-3">
        <Link
          to={"/home/wallet/transactionHistory"}
          className="text-xs font-normal text-clrYellow"
        >
          See All
        </Link>
      </div>
    </div>
  );
};

export default TransactionHistory;
