import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../app/hooks";
import InfoIcon from "../../assets/icons/wallet/stake-info-circle.svg";
import { Button, ButtonOutlineWallet } from "../reusables";
import Arrow from "../../assets/icons/wallet/group-arrow.svg";
import InfoIconGray from "../../assets/icons/wallet/info-circle-gray.svg";
import {
  StakeLockedFlexible,
  StakeRewardClaim,
  StakeSuccess,
  Unstake,
} from "./StakeInfo";
import {
  useGetStakingReportQuery,
  useGetStakingOverviewQuery,
  useStakeMutation,
  useClaimRewardMutation,
  useStakeStatusQuery,
  useUnstakeMutation,
  useForceunlockMutation,
} from "../../features/api/stakingSlice";
import { ScaleLoader } from "react-spinners";
import { numberWithCommas, numberWithCommasWithoutDecimal } from "../../Helper";
import { useGetBalancesQuery } from "../../features/api/walletSlice";
import { useGetMarketQuoteQuery } from "../../features/api/explorerSlice";

const StakeMain = () => {
  //Get User Id
  const { id: userId } = useAppSelector((state) => state.auth);

  //Get user's total balance
  const { data: balances, refetch: refetchUserBalances } =
    useGetBalancesQuery(userId, {skip: !userId});

  //Get market quote
  const { data: marketQuote, isLoading: gettingQuote } =
    useGetMarketQuoteQuery();

  //Get Staking Report
  const {
    data: stakingReport,
    isLoading: gettingStakingReport,
    refetch: refetchStakingDetail,
  } = useGetStakingReportQuery(userId);

  //Get Staking Overview
  const {
    data: stakingOverview,
    isLoading: gettingStakingOverview,
    refetch: refetchStakingOverview,
  } = useGetStakingOverviewQuery();

  //Get WNT balance
  const [wntBalance, setwntBalance] = useState<BalanceProps>();

  //Stake Mutation
  const [stake, { isLoading: stakingWnt }] = useStakeMutation();

  //Claim Mutation
  const [claim, { isLoading: claiming }] = useClaimRewardMutation();

  //Force Unlock Mutation
  const [forceUnlock, { isLoading: unlocking }] = useForceunlockMutation();

  //Get Stake status
  const { data: stakingStatus, refetch: refetchStakingStatus } =
    useStakeStatusQuery();

  //Unstake Mutation
  const [unstake, { isLoading: unstaking }] = useUnstakeMutation();

  //Toggle Staking modals
  const [stakingStep, setStakingStep] = useState<number>(0);

  const [errMsg, setErr] = useState<string>("");

  //Successful message
  const [successMsg, setSuccessMsg] = useState<{
    title: string;
    description: string;
  }>();

  //Function to make request to the staking endpoint
  const handleStaking = (data: { amount: number; duration: number }) => {
    stake(data)
      .unwrap()
      .then((res) => {
        if (res.success) {
          refetchStakingDetail();
          refetchUserBalances();
          refetchStakingStatus();
          setStakingStep(3);
          setSuccessMsg({
            title: "Stake Successful",
            description:
              "Your stake was successful, click the button below to see your balance",
          });
        } else {
          setErr(res?.message);
        }
      })
      .catch((err) => {
        setErr(err?.data?.message);
      });
  };

  //Function to make request to the unstake endpoint
  const handleUnstaking = () => {
    unstake()
      .unwrap()
      .then((res) => {
        if (res.success) {
          refetchStakingDetail();
          refetchUserBalances();
          refetchStakingStatus();
          refetchStakingOverview();
          setStakingStep(3);
          setSuccessMsg({
            title: "UnStake Successful",
            description:
              "Your unstake was successful, click the button below to see your balance",
          });
        } else {
          setErr(res?.message);
        }
      })
      .catch((err) => {
        setErr(err?.data?.message);
      });
  };

  //Function to make request to the unstake endpoint
  const handleForceUnlock = () => {
    forceUnlock()
      .unwrap()
      .then((res) => {
        if (res.success) {
          refetchStakingDetail();
          refetchUserBalances();
          refetchStakingStatus();
          refetchStakingOverview();
          setStakingStep(3);
          setSuccessMsg({
            title: "Force Unlock Successful",
            description:
              "Your force unlock was successful, click the button below to see your balance",
          });
        } else {
          setErr(res?.message);
        }
      })
      .catch((err) => {
        setErr(err?.data?.message);
      });
  };

  //Function to make request to the claim endpoint
  const handleClaim = () => {
    claim()
      .unwrap()
      .then((res) => {
        if (res.success) {
          refetchStakingDetail();
          refetchUserBalances();
          refetchStakingStatus();
          refetchStakingOverview();
          setStakingStep(5);
        }
      })
      .catch((err) => {
        setErr(err?.data?.message || "Something went wrong")
        console.log(err);
      });
  };

  useEffect(() => {
    let walletBalances = balances?.data.balances;
    if (walletBalances) {
      walletBalances.find((balance) => {
        if (balance.symbol === "WNT") {
          setwntBalance(balance);
        }
      });
    }
  }, [balances]);

  return (
    <section className="bg-clrBg dark:bg-clrDarkBg2 w-[91%] max-w-[36rem] mx-auto pb-6 mt-8 overflow-y-auto">
      <div className="px-4 py-6 bg-white rounded dark:bg-clrDarkBg md:px-12">
        <div className="mb-3">
          <h2 className="text-sm md:text-base font-semibold text-[#212121] dark:text-white mb-2 ">
            Staking
          </h2>
          <p className="mb-2 text-[9px] font-medium text-[#949494] dark:text-clrPlaceholder">
            Stake your WNT to earn interest
          </p>
        </div>
        <div
          className={`grid grid-cols-2 md:grid-cols-3 mt-4 gap-x-2 gap-y-4 mb-8 `}
        >
          <div className="h-20 px-4 py-4 bg-clrBg dark:bg-clrDarkBg2 rounded-xl shadow-wxl">
            <h4 className="mb-2 font-semibold text-x10 text-clrGray dark:text-clrPlaceholder">
              Staking Balance
            </h4>
            <p className="text-sm font-semibold text-black dark:text-white">
              {gettingStakingReport ? (
                <ScaleLoader
                  loading={gettingStakingReport}
                  height={12}
                  width={2}
                  color={"#3d5170"}
                />
              ) : (
                <>
                  {" "}
                  {stakingReport?.data
                    ? numberWithCommas(stakingReport?.data?.deposit || 0)
                    : "-----"}{" "}
                  {stakingReport?.data && wntBalance?.symbol}
                </>
              )}
            </p>
            {stakingReport?.data && (
              <div className="flex gap-1">
                <img src={Arrow} alt="changing amount" width={10} height={10} />
                <span className="font-normal text-x8 text-clrGray">
                  ${" "}
                  {numberWithCommas(
                    Number(stakingReport?.data?.deposit) *
                      Number(marketQuote?.data?.marketPrice) || 0
                  )}
                </span>
              </div>
            )}
          </div>
          <div className="h-20 px-4 py-4 bg-clrBg dark:bg-clrDarkBg2 rounded-xl shadow-wxl ">
            <h4 className="mb-2 font-semibold text-x10 text-clrGray dark:text-clrPlaceholder">
              Wallet Balance
            </h4>
            <p className="text-sm font-semibold text-black dark:text-white">
              {gettingStakingReport ? (
                <ScaleLoader
                  loading={gettingStakingReport}
                  height={12}
                  width={2}
                  color={"#3d5170"}
                />
              ) : (
                <>
                  {" "}
                  {numberWithCommas(wntBalance?.balance || 0)}{" "}
                  {wntBalance?.symbol}
                </>
              )}
            </p>
          </div>
          <div className="order-1 h-20 px-4 py-4 bg-clrBg dark:bg-clrDarkBg2 rounded-xl shadow-wxl md:order-none">
            <h4 className="mb-2 font-semibold text-x10 text-clrGray dark:text-clrPlaceholder">
              Total Staked WNT
            </h4>
            <p className="text-sm font-semibold text-black dark:text-white">
              {gettingStakingOverview ? (
                <ScaleLoader
                  loading={gettingStakingOverview}
                  height={12}
                  width={2}
                  color={"#3d5170"}
                />
              ) : (
                <>
                  {" "}
                  {numberWithCommas(
                    (stakingOverview && stakingOverview?.data.totalStakedWNT) ||
                      0
                  ) + " WNT"}{" "}
                </>
              )}
            </p>
          </div>
          {(stakingReport?.data?.reward?.stakingReward || 0) > 0 && (
            <div className="flex items-center justify-between col-span-2 p-4 bg-clrBg dark:bg-clrDarkBg2 rounded-xl shadow-wxl md:col-span-3 bkg-green">
              <div className="mb-1">
                <div className="flex gap-1">
                  <p className="font-semibold uppercase text-x10 text-clrGray dark:text-clrPlaceholder">
                    Claimable Rewards
                  </p>
                  <img src={InfoIconGray} alt="" width={10} height={10} />
                </div>
                <p className="font-semibold text-x10 text-clrYellow2 dark:text-white">
                  {numberWithCommas(
                    stakingReport?.data?.reward?.stakingReward || 0
                  )}
                </p>
              </div>
              <div className="">
                <ButtonOutlineWallet
                  type="button"
                  text="Claim"
                  handleClick={() => setStakingStep(4)}
                  textColor="text-white"
                  bgColor="bg-clrYellow"
                />
              </div>
            </div>
          )}
          <div className="order-2 h-20 px-4 py-4 bg-clrBg dark:bg-clrDarkBg2 rounded-xl shadow-wxl md:order-none">
            <h4 className="mb-2 font-semibold text-x10 text-clrGray dark:text-clrPlaceholder">
              Estimated Rewards
            </h4>
            <p className="text-sm font-semibold text-black dark:text-white">
              {gettingStakingOverview ? (
                <ScaleLoader
                  loading={gettingStakingOverview}
                  height={12}
                  width={2}
                  color={"#3d5170"}
                />
              ) : (
                <>
                  {" "}
                  {stakingOverview &&
                    stakingOverview?.data?.estimatedAPR + "% APR"}{" "}
                </>
              )}
            </p>
          </div>
          <div className="order-3 h-20 px-4 py-4 bg-clrBg dark:bg-clrDarkBg2 rounded-xl shadow-wxl md:order-none">
            <h4 className="mb-2 font-semibold text-x10 text-clrGray dark:text-clrPlaceholder">
              WNT Price
            </h4>
            <p className="text-sm font-semibold text-black dark:text-white">
              {gettingQuote ? (
                <ScaleLoader
                  loading={gettingQuote}
                  height={12}
                  width={2}
                  color={"#3d5170"}
                />
              ) : (
                numberWithCommas(marketQuote?.data?.marketPrice || 0, 3) + "WNT"
              )}
            </p>
          </div>
          <div className="order-4 h-20 px-4 py-4 bg-clrBg dark:bg-clrDarkBg2 rounded-xl shadow-wxl md:order-none">
            <h4 className="mb-2 font-semibold text-x10 text-clrGray">
              Staking Score
            </h4>
            <p className="text-sm font-semibold text-black dark:text-white">
              {gettingStakingReport ? (
                <ScaleLoader
                  loading={gettingStakingReport}
                  height={12}
                  width={2}
                  color={"#3d5170"}
                />
              ) : (
                <>
                  {" "}
                  {stakingReport?.data
                    ? numberWithCommas(
                        stakingReport?.data?.stakingScore || 0,
                        4
                      )
                    : "-----"}
                </>
              )}
            </p>
          </div>
        </div>
        <a
          href="https://wicrypt.com/terms-condition.html"
          target="_blank"
          rel="noopener noreferrer"
          className="font-medium text-x8 text-clrGray opacity-70"
        >
          <div className="flex justify-center items-center gap-2 py-1.5 w-56 mx-auto rounded-md px-2 dark:text-clrPlaceholder bg-clrBg  dark:bg-clrDarkBg2 my-8">
            Staking Terms and Conditions
            <img src={InfoIcon} alt="Information" width={15} height={15} />
          </div>
        </a>
        <div className="flex flex-wrap gap-3 mx-auto mt-8 md:flex-nowrap ">
          {stakingStatus?.data?.stakeButton?.show && (
            <Button
              type="button"
              text={"Stake"}
              handleClick={() => setStakingStep(1)}
            />
          )}
          {stakingStatus?.data?.topUpButton?.show && (
            <Button
              type="button"
              text={"Top Up Stake"}
              handleClick={() => setStakingStep(2)}
            />
          )}
          {stakingStatus?.data?.unstakeButton?.show && (
            <ButtonOutlineWallet
              type="button"
              text="Unstake"
              handleClick={() => setStakingStep(6)}
              wBtn="w-full"
              py="py-2"
            />
          )}
          {stakingStatus?.data?.forceUnlockButton?.show && (
            <ButtonOutlineWallet
              type="button"
              text="Force Unlock"
              handleClick={() => setStakingStep(7)}
              wBtn="w-full"
              py="py-2"
            />
          )}
        </div>
      </div>
      {stakingStep === 1 && (
        <StakeLockedFlexible
          handleModal={() => setStakingStep(0)}
          handleSuccessModal={handleStaking}
          title="Stake"
          balance={wntBalance}
          isLoading={stakingWnt}
          apy={stakingOverview?.data?.estimatedAPR as number}
          error={errMsg}
        />
      )}
      {stakingStep === 2 && (
        <StakeLockedFlexible
          handleModal={() => setStakingStep(0)}
          handleSuccessModal={handleStaking}
          title="Top Up Stake"
          balance={wntBalance}
          isLoading={stakingWnt}
          apy={stakingOverview?.data?.estimatedAPR as number}
          error={errMsg}
        />
      )}
      {stakingStep === 3 && (
        <StakeSuccess
          handleModal={() => setStakingStep(0)}
          title={successMsg?.title || ""}
          description={successMsg?.description || ""}
        />
      )}
      {stakingStep === 4 && (
        <StakeRewardClaim
          handleModal={() => setStakingStep(0)}
          amtToCaim={Number(
            numberWithCommas(stakingReport?.data?.reward?.stakingReward || 0)
          )}
          handleClaim={handleClaim}
          isLoading={claiming}
          errMsg={errMsg}
        />
      )}
      {stakingStep === 5 && (
        <StakeSuccess
          handleModal={() => setStakingStep(0)}
          title="Claim Successful"
          description="Your rewards have been successfully claimed."
        />
      )}
      {stakingStep === 6 && (
        <Unstake
          handleModal={() => setStakingStep(0)}
          amtToUnstake={Number(
            numberWithCommas(stakingReport?.data?.deposit || 0)
          )}
          handleSubmit={handleUnstaking}
          isLoading={unstaking}
          errMsg={errMsg}
          title={"Unstake"}
          description={"Confirm action"}
        />
      )}

      {stakingStep === 7 && (
        <Unstake
          handleModal={() => setStakingStep(0)}
          amtToUnstake={Number(
            numberWithCommas(stakingReport?.data?.deposit || 0)
          )}
          handleSubmit={handleForceUnlock}
          isLoading={unlocking}
          errMsg={errMsg}
          title={"Force Unlock"}
          description={"Confirm action"}
        />
      )}
    </section>
  );
};

export default StakeMain;
