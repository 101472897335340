import React, { useEffect, useRef, useState } from "react";
import ArrowYellow from "../../assets/icons/arrow-down-Yellow.svg";
import Graph from "../reusables/Graph";
import useOutsideClick from "../../Hooks/useOutsideClick";
import {
  useGetDashboardSummaryQuery,
  useGetMiningRewardQuery,
  useGetSessionHistoryQuery
} from "../../features/api/homeSlice";

import { DashboardCard } from "../reusables/DashboardCard";

import { ReactComponent as Devices } from "../../assets/icons/devices-yellow.svg";
import { ReactComponent as Agents } from "../../assets/icons/agents-yellow.svg";
import { ReactComponent as TotalRevenue } from "../../assets/icons/total-revenue.svg";
import { ReactComponent as Voucher } from "../../assets/icons/vouchers-yellow.svg";
import { ReactComponent as DataSold } from "../../assets/icons/data-sold.svg";
import { ReactComponent as DataBought } from "../../assets/icons/data-bought.svg";
import { ReactComponent as DataUsed } from "../../assets/icons/data-used.svg";
import { ReactComponent as Sessions } from "../../assets/icons/sessions.svg";
import { filesize } from "filesize";
import {
  numberWithCommas,
  numberWithCommasWithoutDecimal,
} from "../../Helper";
import moment from "moment";
import { useLazyGetDevicesQuery } from "../../features/api/deviceSlice";
import { ScaleLoader } from "react-spinners";

const Dashboard = () => {
  const [showDetails, setShowDetails] = useState(false);
  const [deviceId, setDeviceId] = useState("");
  const [deviceName, setSelectedDeviceName] = useState("");
  const modalRefDateFilter = useRef<HTMLElement>(null);
  const [tabStatus, saveTab] = useState(1);

  //Save the labels and values for the graph
  const [labels, setGraphLabels] = useState<string[]>([]);

  const [graphData, setGraphValues] = useState<number[]>([]);

  const [sessionLabels, setSessionGraphLabels] = useState<string[]>([]);

  const [sessionGraphData, setSessionGraphValues] = useState<number[]>([]);

  const handleCloseModal = () => {
    setShowDetails(false);
  };

  useOutsideClick(modalRefDateFilter, handleCloseModal);

  const { data, isLoading } = useGetDashboardSummaryQuery();
  const { data: miningRewards } = useGetMiningRewardQuery(deviceId);
  const { data: sessionHistory } = useGetSessionHistoryQuery(deviceId);


  //Get all hotspot hubs
  const  [trigger, { isLoading: gettingDevices, data: generalDevices }] = useLazyGetDevicesQuery();

  const findDevice = () =>{
    trigger(
      {
        deviceName: deviceName,
        deviceStatus: "",
        page: 1,
        pageSize: 30,
      }
    )
  }

  useEffect(() => {
    if (miningRewards) {
      let dates: string[] = [];
      let reward: number[] = [];
      let rewardData = miningRewards?.data?.graphData || [];
      if (rewardData && Number(rewardData?.length) !== 0) {
        rewardData?.map((each) => {
          dates.push(moment(each.date).format("MM/DD/YYYY"));
          reward.push(each.reward);
        });
      }
      setGraphLabels(dates);
      setGraphValues(reward);
    }
  }, [miningRewards]);

  useEffect(() => {
    if (sessionHistory) {
      let dates: string[] = [];
      let reward: number[] = [];
      let rewardData = sessionHistory?.data?.graphData || [];
      if (rewardData && Number(rewardData?.length) !== 0) {
        rewardData?.map((each) => {
          dates.push(`Week ${each.week}, ${each.year}`);
          reward.push(each.revenue);
        });
      }
      setSessionGraphLabels(dates);
      setSessionGraphValues(reward);
    }
  }, [sessionHistory]);


  return (
    <div className="py-3 bg-clrBg w-[96%] mx-auto pb-6 mt-3 dark:bg-clrDarkBg2 overflow-y-auto">
      <div className="grid grid-cols-2 gap-2 sm:grid-cols-2 xl:grid-cols-4 md:gap-y-4 md:gap-x-6">
        <DashboardCard
          isLoading={isLoading}
          icon={<Devices height="30px" className="self-start" width="30px" />}
          title={"Hotspot Hubs"}
          value={data?.data?.totalHotspotHubs || 0}
          link={"/home/hotspot-hubs"}
        />
        <DashboardCard
          isLoading={isLoading}
          icon={<Agents height="30px" className="self-start" width="30px" />}
          title={"Agents"}
          value={data?.data?.totalAgents || 0}
          link={"/home/agents"}
        />
        <DashboardCard
          isLoading={isLoading}
          icon={
            <TotalRevenue height="30px" className="self-start" width="30px" />
          }
          title={`Data Resell Revenue (USD)`}
          value={numberWithCommas(data?.data?.revenueInUSD || 0) || 0}
          link={"/home/wallet"}
        />
        {/* <DashboardCard
          isLoading={isLoading}
          icon={<Voucher height="30px" className="self-start" width="30px" />}
          title={`Voucher`}
          value={data?.data?.numbersofVouchersGenerated || 0}
          link={"/home/vouchers"}
        /> */}
        <DashboardCard
          isLoading={isLoading}
          icon={<Voucher height="30px" className="self-start" width="30px" />}
          title={`Tokens`}
          value={data?.data?.numberOfTokensGenerated || 0}
          link={"/home/token"}
        />
        <DashboardCard
          isLoading={isLoading}
          icon={<DataSold height="30px" className="self-start" width="30px" />}
          title={`Data sold`}
          value={filesize(data?.data?.dataSoldInBytes || 0) || 0}
          info={"This is the total data sold"}
        />
        <DashboardCard
          isLoading={isLoading}
          icon={
            <DataBought height="30px" className="self-start" width="30px" />
          }
          title={`Data Bought`}
          value={filesize(data?.data?.dataBoughtInBytes || 0) || ""}
          info={"This is the total data bought"}
        />
        <DashboardCard
          isLoading={isLoading}
          icon={<DataUsed height="30px" className="self-start" width="30px" />}
          title={`Data Used`}
          value={filesize(data?.data?.dataUsedInBytes || 0) || ""}
          info={"This is the total data used"}
        />
        <DashboardCard
          isLoading={isLoading}
          icon={<Sessions height="30px" className="self-start" width="30px" />}
          title={`Sessions`}
          value={
            numberWithCommasWithoutDecimal(data?.data?.sessionsCount || 0) || 0
          }
          info={"These are all connections to this user's devices"}
        />
      </div>
      <div className="grid gap-6 mt-6 md:grid-cols-2">
        <div className="w-[295px] min-[325px]:w-[350px] min-[376px]:w-auto p-4 bg-white dark:bg-clrDarkBg rounded-[7px] relative">
          <div className="flex items-center justify-between pb-4 border-b-[1px] border-solid border-clrBorder flex-wrap gap-2">
            <div className=" bg-clrBg2 tracking-[0.01em] rounded-md tab-container flex">
              <div className={tabStatus === 1 ? "bg-clrYellow2 rounded-md p-1 tab-option text-white" : " tab-option"} onClick={() => saveTab(1)}>
                <p className="text-[10px]">Mining Reward  </p>
              </div>
              <div className={tabStatus === 2 ? "bg-clrYellow2 rounded-md p-1 tab-option text-white" : "tab-option"} onClick={() => saveTab(2)}>
                <p className="text-[10px]">Data Resell Revenue </p>
              </div>
            </div>
            <div className="bg-clrBg2 tracking-[0.01em] rounded-md tab-container flex search-container text-sm p-2">
              <input className="bg-clrBg2  rounded-md tab-container text-sm pl-2 outline-none w-10/12"
                  onChange={(e)=> setSelectedDeviceName(e.target.value)}
                  onBlur={() =>{setShowDetails(!showDetails); findDevice()}}
                  placeholder="All Hubs"
                  value={deviceName}
                />
                <img onClick={() => {setShowDetails(!showDetails); findDevice()}} src={ArrowYellow} alt="Arrow down" width="20px" height="12px"/>
            </div>

            {showDetails &&
              <>
                <div className="absolute z-30 right-0 p-4 space-y-2 bg-white dark:bg-clrDarkBg rounded-md shadow top-[4rem] w-fit dark:border-clrPlaceholder dark:border-[1px]">

                  <div className="mb-2 space-y-2 text-xs font-normal text-clrGray2 dark:text-clrPlaceholder">
                    <p className={deviceId === "" ? "mb-3 text-sm font-semibold text-clrTextBlue dark:text-white" : `cursor-pointer`} 
                      onClick={() => { setDeviceId(""); setSelectedDeviceName("All Devices"); setShowDetails(!showDetails) }} >
                      All Devices
                    </p>
                    {gettingDevices ?

                      <ScaleLoader
                        loading={gettingDevices}
                        height={12}
                        width={2}
                        color={"#3d5170"}
                      />
                      : generalDevices?.data?.records.map((device) => {
                        return (
                          <p className={device.id === deviceId ? "mb-3 text-sm font-semibold text-clrTextBlue dark:text-white" : `cursor-pointer`}
                            onClick={() => { setDeviceId(device?.id); setSelectedDeviceName(device?.name); setShowDetails(!showDetails) }}>{device.name}</p>
                        );
                      })}
                  </div>
                </div>
                {/* <div
                className="fixed inset-0 z-20 h-screen overflow-hidden bg-transparent"
                ref={modalRefDateFilter}
              ></div> */}
              </>
              // <DateFilter modalRef={modalRefDateFilter} />

            }
          </div>
          <div className="flex items-center gap-2 py-4">
            <div className="w-[10px] h-[10px] rounded-[50%] border-2 border-clrDarkBlue dark:border-white"></div>
            <p className="text-xs font-normal text-[#192A3E] dark:text-white">
              WNT
            </p>
          </div>
          {tabStatus === 1 ? <Graph labels={labels} graphData={graphData} /> : <Graph labels={sessionLabels} graphData={sessionGraphData} />}
        </div>
        <div className="p-4 bg-white dark:bg-clrDarkBg rounded-[7px]">
          <h3 className="text-sm xl:text-base font-semibold text-black dark:text-white tracking-[0.01em] mb-7">
            Summary
          </h3>
          <div className="pt-5 pb-5 border-b-[1px] border-solid border-clrBorder">
            <p className="text-xs font-bold text-black dark:text-clrPlaceholder tracking-[0.01em]">
              {data?.data?.mostUsedDevice || "N/A"}
            </p>
            <p className="text-xs font-normal text-clrGray dark:text-clrPlaceholder tracking-[0.01em]">
              Most Used
            </p>
          </div>
          <div className="pt-5 pb-5 border-b-[1px] border-solid border-clrBorder">
            <p className="text-xs font-bold text-black dark:text-clrPlaceholder tracking-[0.01em]">
              {data?.data?.topRevenueDevice || "N/A"}
            </p>
            <p className="text-xs font-normal text-clrGray dark:text-clrPlaceholder tracking-[0.01em]">
              Hub with Most Sessions
            </p>
          </div>
          <div className="pt-5 pb-4">
            <p className="text-xs font-bold text-black dark:text-clrPlaceholder tracking-[0.01em]">
              {data?.data?.topRevenueDevice || "N/A"}
            </p>
            <p className="text-xs font-normal text-clrGray dark:text-clrPlaceholder tracking-[0.01em]">
              Hub witih Most Resell Revenue
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
