import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query/react";
import { apiSlice, appealSlice, explorerSlice, stakingApi} from "../features/api/apiSlice";
import authReducerSlice from "../features/store/authReducerSlice";
import walletReducerSlice from "../features/store/walletReducerSlice";


export const store = configureStore({
    reducer: {
        [apiSlice.reducerPath]: apiSlice.reducer,
        [appealSlice.reducerPath]: appealSlice.reducer,
        [explorerSlice.reducerPath]: explorerSlice.reducer,
        [stakingApi.reducerPath]: stakingApi.reducer,
        auth: authReducerSlice,
        wallet: walletReducerSlice
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(apiSlice.middleware, appealSlice.middleware, explorerSlice.middleware, stakingApi.middleware)

})

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>
setupListeners(store.dispatch);