import React, { ReactNode, useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as ArrowYellow } from "../../assets/icons/arrow-down-Yellow.svg";
import { ReactComponent as InfoIcon } from "../../assets/icons/info-circle.svg";
import { ScaleLoader } from "react-spinners";

/**
 * Represents the props for a dashboard card component.
 */
interface DashboardCardProps {
  icon: ReactNode; // The icon component for the card.
  title: string; // The title of the card.
  info?: string; // Optional additional information for the card.
  // If this is present, we show the arrow icon.
  value: any; // The numerical value displayed on the card.
  link?: string; // Optional link associated with the card.
  // If this is present, user can navigate to another
  // part of the dashboard from the card.
  isLoading: boolean;
}

/**
@component
Creates a Dashboard card component with optional features 
@param {DashboardCardProps} props
* */

export const DashboardCard = ({
  icon,
  title,
  value,
  link,
  info,
  isLoading,
}: DashboardCardProps) => {
  // State to track tooltip visibility
  const [isTooltipVisible, setTooltipVisible] = useState(false);
  // Function to handle the tooltip visibility
  const handleTooltipToggle = (event: React.MouseEvent) => {
    setTooltipVisible((prevState) => !prevState);
  };
  return (
    <div className="flex gap-2 md:gap-4 bg-white dark:bg-clrDarkBg rounded-[7px] py-2.5 px-2 h-[90px] md:h-[100px]">
      {icon}
      <div className="flex flex-col justify-between mr-auto">
        <div className="flex gap-2 mt-1.5">
          <p className="text-x10 md:text-xs font-semibold text-[#747474] dark:text-clrPlaceholder whitespace-break-spaces">
            {title}
          </p>
          {info && (
            <div
              className="relative cursor-pointer"
              onMouseEnter={handleTooltipToggle} // Show tooltip on mouse enter
              onMouseLeave={handleTooltipToggle} // Hide tooltip on mouse leave
            >
              <InfoIcon
                width={"15px"}
                height={"15px"}
                className="w-[13px] h-[13px] md:w-[15px] md:h-[15px]"
              />
              <div
                className={`tooltip relative ${
                  isTooltipVisible ? "show" : "hidden"
                }`}
              >
                <div className="absolute top-1 left-0 w-0 h-0 border-l-transparent border-solid border-r-transparent border-b-black border-l-[6px] border-r-[6px] border-b-[6px]"></div>{" "}
                {/* Add pointer */}
                <div className="font-normal text-x8 absolute bg-black text-white p-1 rounded z-[1] top-2 -left-1 w-32">
                  {/* Add tooltip content */}
                  {info}
                </div>
              </div>
            </div>
          )}
        </div>
        <p className="text-xl md:text-2xl font-semibold text-[#1d1d1d] dark:text-white">
          {isLoading ? (
            <ScaleLoader
              loading={isLoading}
              height={12}
              width={2}
              color={"#3d5170"}
            />
          ) : (
            value
          )}
        </p>
      </div>
      {link && (
        <Link to={link} className="self-center">
          <ArrowYellow
            width={"20px"}
            height={"20px"}
            className="transform -rotate-90"
          />
        </Link>
      )}
    </div>
  );
};
