import { ReactComponent as Dashboard } from "../../assets/icons/dashboard.svg";
import { ReactComponent as Wallet } from "../../assets/icons/wallet.svg";
import { ReactComponent as HotspotHubs } from "../../assets/icons/hotspot-hubs.svg";
import { ReactComponent as Explorer } from "../../assets/icons/explorer.svg";
import { ReactComponent as RewardBooster } from "../../assets/icons/reward-booster.svg";
import { ReactComponent as Voucher } from "../../assets/icons/voucher.svg";

import { ReactComponent as Referrals } from "../../assets/icons/referrals.svg";
import { ReactComponent as Agents } from "../../assets/agents.svg";
// import { ReactComponent as Business } from "../../assets/icons/business.svg";
// import { ReactComponent as Service } from "../../assets/icons/service-chrage.svg";
// import { ReactComponent as RouterLanding } from "../../assets/icons/router-landing.svg";
import { ReactComponent as Profile } from "../../assets/icons/profile-icon.svg";
// import { ReactComponent as Subscription } from "../../assets/icons/subscription.svg";
import { ReactComponent as Token } from "../../assets/icons/Token.svg";
// import { ReactComponent as Advert } from "../../assets/icons/advert.svg";
// import { ReactComponent as Setting } from "../../assets/icons/setting.svg";
import { ReactComponent as LogOut } from "../../assets/icons/logout.svg";

export const NavBarData = [
  {
    id: 1,
    icon: <Dashboard />,
    title: "Dashboard",
    desc: "Track your activities across your Hotspot Hubs",
    to: "/home/dashboard",
    path: "dashboard",
  },
  {
    id: 7,
    icon: <Referrals />,
    title: "Referrals",
    desc: "View your referral code and invite your friends ",
    to: "/home/referrals",
    path: "referrals",
  },
  {
    id: 2,
    icon: <Wallet />,
    title: "Wallet",
    desc: "Track your wallet operations",
    to: "/home/wallet",
    path: "wallet",
  },
  {
    id: 3,
    icon: <HotspotHubs />,
    title: "Hotspot Hubs",
    desc: "Explore the world of decentralized Internet Sharing",
    to: "/home/hotspot-hubs",
    path: "hotspot-hubs",
  },
  {
    id: 4,
    icon: <Explorer />,
    title: "My Explorer",
    desc: "An intelligent mapping system that allows users see all the Wicrypt hotspot hubs near them",
    to: "/home/explorer",
    path: "explorer",
  },
  {
    id: 5,
    icon: <RewardBooster />,
    title: "Reward Booster",
    desc: "Lock up your weekly rewards to maximize interest over time.",
    to: "/home/booster",
    path: "booster",
  },
  {
    id: 11,
    icon: <Profile />,
    title: "Profile",
    desc: "View your profile information",
    to: "/home/profile",
    path: "profile",
  },
];

export const NavBarDataMore = [
  // {
  //   id: 6,
  //   icon: <Voucher />,
  //   title: "Voucher",
  //   desc: "View your Voucher Statistics",
  //   to: "/home/vouchers",
  //   path: "vouchers",
  // },
  // {
  //   id: 8,
  //   icon: <Business />,
  //   title: "Sub Buisnesses",
  //   desc: "Track your apps across hubs",
  //   to: "/home",
  // },
  // {
  //   id: 9,
  //   icon: <Service />,
  //   title: "Service Charge",
  //   desc: "Track your apps across hubs",
  //   to: "/home",
  // },
  // {
  //   id: 10,
  //   icon: <RouterLanding />,
  //   title: "Router landing page",
  //   desc: "Track your apps across hubs",
  //   to: "/home",
  // },

  // {
  //   id: 12,
  //   icon: <Subscription />,
  //   title: "Subscription",
  //   desc: "Track your apps across hubs",
  //   to: "/home",
  // },
  {
    id: 13,
    icon: <Token />,
    title: "Token",
    desc: "View your Token statistics ",
    to: "/home/token",
    path: "token",
  },
  {
    id: 14,
    icon: <Agents />,
    title: "My Agents",
    desc: "View your Agents’ activities across your app",
    to: "/home/agents",
    path: "agents",
  },
  // {
  //   id: 15,
  //   icon: <Setting />,
  //   title: "Setting",
  //   desc: "Track your apps across hubs",
  //   to: "/home",
  // },
  {
    id: 16,
    icon: <LogOut />,
    title: "Log out",
    desc: "Are you sure you want to log out?",
    to: "/home/logOut",
  },
];

// export const ReportData = [
//   {
//     icon: WalletYellow,
//     Title: "Revenue",
//     value: "NGN60,00000",
//     rate: "40%",
//   },
//   {
//     icon: ChartIcon,
//     Title: "Sessions",
//     value: "1000",
//     rate: "40%",
//   },
//   {
//     icon: SwitchIcon,
//     Title: "Data Used",
//     value: "50GB",
//     rate: "40%",
//   },
// ];
