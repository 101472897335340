import React, { useEffect, useState } from "react";
import {
  CaptureDocument,
  CaptureSelfie,
  ChooseID,
  ConfirmApproval,
  SelectRegion,
} from "./ProfileModals";
import {
  ValidPhotoID,
  VerifyOnGoing,
  VerifyPage,
  VerifyPhotoID,
} from "./VerifyPic";
import { useNavigate } from "react-router";
import { useAppSelector } from "../../app/hooks";
import { useVerifyUserMutation } from "../../features/api/profileSlice";

const VerifyID = () => {
  const { verificationStatus } = useAppSelector((state) => state.auth);

  // Modals
  const [step, setVerificationStep] = useState<number>(0);

  //Pages
  const [page, setPage] = useState<number>(0);

  //Selected ID
  const [idType, setIdType] = useState<string>("");

  //Selected Region
  const [region, setRegion] = useState<string>("");

  //ID
  const [idImage, setIdImage] = useState<string>("");

  //Selfie image
  const [selfieImage, setSelfieImage] = useState<string>("");

  //Selfie image
  const [errMsg, setErr] = useState<string>("");

  //verification hook
  const [verifyUser, { isLoading }] = useVerifyUserMutation();

  const navigate = useNavigate();

  //Choose ID type
  const handleChooseId = (idType: string) => {
    setVerificationStep(2);
    setIdType(idType);
  };

  //Choose Region
  const handleChooseRegion = (region: string) => {
    setVerificationStep(3);
    setRegion(region);
  };

  //Choose ID
  const handleId = (idImage: string) => {
    setPage(2);
    setVerificationStep(0);
    setIdImage(idImage);
  };

  const handleSelfie = (selfieImage: string) => {
    setVerificationStep(5);
    setSelfieImage(selfieImage);
  };

  const handleVerifyUser = () => {
    let data = {
      identityCardImage: idImage,
      selfieImage: selfieImage,
      idType: idType,
      countryCode: region,
    };
    verifyUser(data)
      .unwrap()
      .then((res) => {
        if (res.success) {
          setVerificationStep(0);
          setPage(3);
        } else {
          setErr(res.message);
        }
      })
      .catch((err) => {
        setErr(err?.data?.message);
      });
  };

  useEffect(() => {
    if (verificationStatus.toLowerCase() === "pending") {
      setPage(3);
    }
  }, [verificationStatus]);

  return (
    <>
      <section className="w-[96%] mx-auto pt-3 pb-6 h-full overflow-y-auto">
        {page === 0 && <VerifyPage handlePage={() => setPage(1)} />}
        {page === 1 && (
          <ValidPhotoID
            handlePage={() => {
              setVerificationStep(1);
            }}
          />
        )}
        {page === 2 && (
          <VerifyPhotoID
            handlePage={() => {
              setVerificationStep(4);
            }}
          />
        )}
        {page === 3 && (
          <VerifyOnGoing
            handlePage={() => {
              navigate("/home/profile");
            }}
          />
        )}
      </section>

      {step === 1 && (
        <ChooseID
          handleCloseModal={() => setVerificationStep(0)}
          handleSubmit={handleChooseId}
        />
      )}
      {step === 2 && (
        <SelectRegion
          handleCloseModal={() => setVerificationStep(0)}
          handleGoBack={() => setVerificationStep(1)}
          handleSubmit={handleChooseRegion}
        />
      )}
      {step === 3 && (
        <CaptureDocument
          handleCloseModal={() => setVerificationStep(0)}
          handleSubmit={handleId}
          handleGoBack={() => setVerificationStep(2)}
          idType={idType}
        />
      )}
      {step === 4 && (
        <CaptureSelfie
          handleCloseModal={() => setVerificationStep(0)}
          handleSubmit={handleSelfie}
        />
      )}
      {step === 5 && (
        <ConfirmApproval
          handleCloseModal={() => setVerificationStep(0)}
          handleSubmit={handleVerifyUser}
          handleGoBack={() => setVerificationStep(4)}
          isLoading={isLoading}
          errMsg={errMsg}
        />
      )}
    </>
  );
};

export default VerifyID;
