interface Config {
  apiUrl: string;
  explorerUrl: string;
  explorerMain: string;
  disputeBaseUrl: string;
  appName: string;
  subject: string;
  baseUrl: string;
  stakingBaseUrl: string;
}

let localPlatforms = [
  "localhost",
  "sandbox.wicrypt.com",
  "enterscale.wicrypt.com"
];

let platform = localPlatforms.includes(window.location.hostname)
  ? "local"
  : "production";

let config: Config; // Define the type of config

if (platform === "local") {
  config = {
    apiUrl: process.env.REACT_APP_API_URL!,
    explorerUrl: process.env.REACT_APP_EXPLORER_URL!,
    explorerMain: process.env.REACT_APP_EXPLORER_URL_MAIN!,
    disputeBaseUrl: process.env.REACT_APP_DISPUTE_BASE_URL!,
    stakingBaseUrl: process.env.REACT_APP_STAKING_URL!,
    appName: "Wicrypt",
    subject: "Wicrypt",
    baseUrl: ""
  };
} else {
  config = {
    apiUrl: process.env.REACT_APP_API_URL!,
    explorerUrl: process.env.REACT_APP_EXPLORER_URL!,
    explorerMain: process.env.REACT_APP_EXPLORER_URL_MAIN!,
    disputeBaseUrl: process.env.REACT_APP_DISPUTE_BASE_URL!,
    stakingBaseUrl: process.env.REACT_APP_STAKING_URL!,
    appName: "Wicrypt",
    subject: "Wicrypt",
    baseUrl: ""
  };
}

export default config;
