import { ReactComponent as InfoIcon } from "../../assets/icons/information-circle.svg";
import ChevronUpIcon from "../../assets/icons/wallet/chevron-double-up.svg";
import MapDetails from "./MapDetails";
import GraphCard from "../reusables/Graph";
import { useGetHotspothubstatisticsQuery } from "../../features/api/explorerSlice";
import { useParams } from "react-router";
import { numberWithCommas } from "../../Helper";
import { useEffect, useState } from "react";
import moment from "moment";
import { ScaleLoader } from "react-spinners";
import DeviceDetails from "./DeviceDetails";
import { ReactComponent as Star } from "../../assets/icons/star.svg";

const Statistics = () => {
  //Get the tokenid from the route
  const { tokenId } = useParams();

  //Get the hotspothub statistics
  const { data: hubDetail, isLoading: gettingHubDetail } =
    useGetHotspothubstatisticsQuery({
      tokenId: tokenId as string,
      lookbackPeriodInHours: "",
      chartLookbackPeriodInDays: "",
    }, {skip: !tokenId});

  //Save the labels and values for the graph
  const [labels, setGraphLabels] = useState<string[]>([]);
  const [graphData, setGraphValues] = useState<number[]>([]);

  useEffect(() => {
    if (hubDetail) {
      let dates: string[] = [];
      let pointvalues: number[] = [];
      if (hubDetail.data?.earnings?.earningChartPoints?.length !== 0) {
        let data = hubDetail?.data.earnings?.earningChartPoints;
        data?.map((each) => {
          dates.push(moment(each.date).format("MMMM Do YYYY"));
          pointvalues.push(each.amount);
        });
      }
      setGraphLabels(dates);
      setGraphValues(pointvalues);
    }
  }, [hubDetail]);

  const RatingDisplay = ({ rating }: { rating: number }) => {
    return (
      <div className="flex justify-start">
        <div className="flex gap-1 md:gap-2">
          {[1, 2, 3, 4, 5].map((index) => (
            <div className={`star ${index <= rating ? "rated" : "unrated"}`}>
              <Star key={index} />
            </div>
          ))}
        </div>
      </div>
    );
  };

  // State to track tooltip visibility for Sync Status
  const [isSyncStatusTooltipVisible, setSyncStatusTooltipVisible] =
    useState(false);

  // State to track tooltip visibility for Ratings
  const [isRatingsTooltipVisible, setRatingsTooltipVisible] = useState(false);

  // Function to handle the tooltip visibility
  const handleSyncStatusTooltipToggle = (event: React.MouseEvent) => {
    setSyncStatusTooltipVisible((prevState) => !prevState);
  };

  const handleRatingsTooltipToggle = (event: React.MouseEvent) => {
    setRatingsTooltipVisible((prevState) => !prevState);
  };

  return (
    <section className="w-[96%] mx-auto pb-6 h-full mt-3 overflow-y-auto">
      <MapDetails />
      <div className="p-4 mt-4 bg-white rounded-lg dark:bg-clrDarkBg md:py-6 md:px-12">
        <DeviceDetails />
        <div className="grid grid-cols-2 pt-2.5 pb-4 md:pb-20 my-2 bg-white dark:bg-clrDarkBg rounded-lg gap-3 md:grid-cols-6 md:gap-y-5 md:gap-x-9">
          <div className="flex flex-col gap-2 bg-clrBg2 dark:bg-clrDarkBg2 rounded-[10px] p-3 md:p-5 shadow-wxl h-24 md:h-32 md:col-start-1 md:col-end-3">
            <p className="text-xs font-semibold md:text-sm text-clrGray66 dark:text-clrPlaceholder">
              Connections
            </p>
            <p className="text-lg font-bold md:text-xl text-clrTextGray dark:text-white">
              {" "}
              {gettingHubDetail ? (
                <ScaleLoader
                  loading={gettingHubDetail}
                  height={12}
                  width={2}
                  color={"#3d5170"}
                />
              ) : (
                hubDetail?.data.connections
              )}
            </p>
            <div className="flex items-center gap-1">
              <div className="bg-[#5FBE91] flex items-center justify-center rounded-sm p-0.5">
                <img src={ChevronUpIcon} alt="Increase" width={9} height={9} />
              </div>
              <p className="font-normal text-x11 text-clrGreen2 dark:text-white">
                {" "}
                {gettingHubDetail ? (
                  <ScaleLoader
                    loading={gettingHubDetail}
                    height={12}
                    width={2}
                    color={"#3d5170"}
                  />
                ) : (
                  hubDetail?.data.connectionsChangePercentage
                )}
                %
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-2 md:gap-3 bg-clrBg2 dark:bg-clrDarkBg2 rounded-[10px] p-3 md:p-5 shadow-wxl h-24 md:h-32 md:col-start-3 md:col-end-5">
            <div className="flex items-center gap-2">
              <p className="text-xs font-semibold md:text-sm text-clrGray66 dark:text-clrPlaceholder">
                Sync Status
              </p>
              {/* <img src={InfoIcon} alt="Increase" width={20} height={20} /> */}

              <div
                className="relative cursor-pointer"
                onMouseEnter={handleSyncStatusTooltipToggle} // Show tooltip on mouse enter
                onMouseLeave={handleSyncStatusTooltipToggle} // Hide tooltip on mouse leave
              >
                <InfoIcon
                  width={"15px"}
                  height={"15px"}
                  className="w-[13px] h-[13px] md:w-[15px] md:h-[15px]"
                />
                <div
                  className={`tooltip relative ${
                    isSyncStatusTooltipVisible ? "show" : "hidden"
                  }`}
                >
                  <div className="absolute top-1 left-0 w-0 h-0 border-l-transparent border-solid border-r-transparent border-b-black border-l-[9px] border-r-[9px] border-b-[8px]"></div>{" "}
                  {/* Add pointer */}
                  <div className="font-normal text-x8 absolute bg-black text-white p-1 rounded z-[1] top-[11.8px] -left-[9.5px] w-24">
                    {/* Add tooltip content */}
                    This is the current sync status of the device
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center gap-1">
              {hubDetail?.data.isActive ? (
                <div className="w-4 h-4 rounded-full bg-clrGreen"></div>
              ) : (
                <div className="w-4 h-4 rounded-full bg-clrRed2"></div>
              )}
              <p className="text-lg font-bold text-black dark:text-white md:text-xl ">
                {hubDetail?.data.isActive ? "Online" : "Offline"}
              </p>
            </div>
          </div>
          <div className="flex flex-col justify-between bg-clrBg2 dark:bg-clrDarkBg2 rounded-[10px] p-3 md:p-5 shadow-wxl h-24 md:h-32 md:col-start-5 md:col-end-7">
            <div className="flex items-center gap-2">
              <p className="text-sm font-semibold text-clrGray66 dark:text-clrPlaceholder">
                Ratings
              </p>
              {/* <img src={InfoIcon} alt="Increase" width={20} height={20} /> */}

              <div
                className="relative cursor-pointer"
                onMouseEnter={handleRatingsTooltipToggle} // Show tooltip on mouse enter
                onMouseLeave={handleRatingsTooltipToggle} // Hide tooltip on mouse leave
              >
                <InfoIcon
                  width={"15px"}
                  height={"15px"}
                  className="w-[13px] h-[13px] md:w-[15px] md:h-[15px]"
                />
                <div
                  className={`tooltip relative ${
                    isRatingsTooltipVisible ? "show" : "hidden"
                  }`}
                >
                  <div className="absolute top-1 left-0 w-0 h-0 border-l-transparent border-solid border-r-transparent border-b-black border-l-[9px] border-r-[9px] border-b-[8px]"></div>{" "}
                  {/* Add pointer */}
                  <div className="font-normal text-x8 absolute bg-black text-white p-1 rounded z-[1] top-[11.8px] -left-[9.5px] w-24">
                    {/* Add tooltip content */}
                    This is the average rating by users of your device
                  </div>
                </div>
              </div>
            </div>
            <RatingDisplay rating={hubDetail?.data.rating as number} />
          </div>

          <div className="flex flex-col gap-2 bg-clrBg2 dark:bg-clrDarkBg2 rounded-[10px] shadow-wxl pt-3 md:pt-4 h-fit md:h-[8.758rem] col-start-1 col-end-3 md:col-start-1 md:col-end-4">
            <div className="flex items-start justify-between px-3 md:px-4">
              <div>
                <p className="font-semibold text-x11 text-clrGray66 dark:text-clrPlaceholder">
                  Earnings
                </p>
                <p className="text-base font-bold md:text-lg text-clrTextGray dark:text-white">
                  {gettingHubDetail ? (
                    <ScaleLoader
                      loading={gettingHubDetail}
                      height={12}
                      width={2}
                      color={"#3d5170"}
                    />
                  ) : (
                    numberWithCommas(
                      hubDetail?.data?.earnings?.totalEarnings || 0
                    )
                  )}
                </p>
              </div>
              <div className="flex items-start h-auto gap-2">
                <button className="rounded bg-white px-1 py-0.5 ">
                  <p className="font-normal text-x8 text-clrTextBlue">24H</p>
                </button>
                <button className="rounded bg-[#fafafa] px-1 py-0.5">
                  <p className="text-x8 font-normal text-[#2E2E2E]">7D</p>
                </button>
                <button className="rounded bg-[#fafafa] px-1 py-0.5">
                  <p className="text-x8 font-normal text-[#2E2E2E]">30D</p>
                </button>
              </div>
            </div>

            <div className="">
              <GraphCard
                labels={labels}
                graphData={graphData}
                height={71}
                width={500}
                showXAxis={false}
                showYAxis={false}
                borderColor="#25346A"
                bgColor1="rgba(37, 52, 106, 0.20)"
                bgColor2="rgba(196, 196, 196, 0.00) "
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Statistics;
