import React, { useState, useRef } from "react";
import TableMain from "../reusables/ReactDataTable";
import { ButtonYellow, EmptyCard, Filter, TableLoader } from "../reusables";
import Whitepaper from "../../assets/icons/hotspot-hubs/whitepaper.svg";
import { columnsToken } from "./data";
import { MobileFilterSearch } from "../hotspotHubs/OptionsStatus";
import { useGetTokensQuery } from "../../features/api/tokenSlice";
import useOutsideClick from "../../Hooks/useOutsideClick";
import { GenerateToken } from "./TokenModals";

//Component  to render the token history page
const TokenHistory: React.FC = () => {
  const modalRef = useRef<HTMLElement>(null);
  const [option, toggleOption] = useState<string>("");
  const [status, setStatus] = useState<string>("All");
  const [type, setType] = useState<string>("All");
  const [searchValue, setSearchValue] = useState<string>("");
  const [page, setPage] = useState<number>(1);

  const {
    data,
    isLoading: loadingTokenHistory,
    isFetching,
  } = useGetTokensQuery({
    voucherStatus: status,
    search: searchValue.replace(/-/g, ''),
    dataTokenUsageType: type,
    page: page,
    pageSize: 30,
  });

  //   Modal to generate token
  const [generateToken, setGenerateToken] = useState(false);

  const handleCloseModal = () => {
    toggleOption("");
    setGenerateToken(false);
  };

  useOutsideClick(modalRef, handleCloseModal);

  return (
    <div>
      <div className="flex flex-wrap items-end w-full gap-x-3 gap-y-3 md:px-4 md:w-auto">
        <div className="flex w-full gap-3 md:w-auto">
          <MobileFilterSearch>
            <Filter
              modalRef={modalRef}
              options={["All", "Active", "Inactive", "Exhausted", "Expired"]}
              toggleFilter={toggleOption}
              selectedFilterOption={option}
              currentFilterOption={"STATUS"}
              selectFilterOption={setStatus}
              selectedOption={status}
              label={"Status"}
            />
            <Filter
              modalRef={modalRef}
              options={["All", "Timed", "Data"]}
              toggleFilter={toggleOption}
              selectedFilterOption={option}
              currentFilterOption={"TYPE"}
              selectFilterOption={setType}
              selectedOption={type}
              label={"Usage Type"}
            />
          </MobileFilterSearch>
          <div className="flex-1 md:flex-none">
            <label
              htmlFor=""
              className="hidden md:block mb-2.5 text-xs font-semibold text-clrTextLabel dark:text-clrPlaceholder"
            >
              Search
            </label>
            <input
              type="text"
              name="searchValue"
              id="searchValue"
              value={searchValue}
              placeholder="Token"
              onChange={(e) => setSearchValue(e.target.value)}
              className="w-full md:w-fit text-xs font-medium text-clrGray dark:text-clrPlaceholder border-[1px] border-solid border-clrBorder dark:border-clrGray rounded-3xl h-8 pb-1 px-4 placeholder:!text-clrPlaceholder dark:placeholder:text-clrPlaceholder placeholder:text-[10px] bg-inherit dark:bg-transparent"
            />
          </div>
        </div>
        <button onClick={() => setGenerateToken(true)}>
          <ButtonYellow text="Generate Token" btnIcon={Whitepaper} to={""} />
        </button>
      </div>
      {loadingTokenHistory || isFetching? (
        <TableLoader />
      ) : (
        <TableMain 
          defaultData={data?.data?.records || []} 
          columns={columnsToken} 
          totalPages={data?.data?.totalPages || 0}
          pageSize={data?.data.pageSize || 0}
          currentPage = {data?.data?.currentPage || 0}
          setPage={setPage}
        />
      )}
      {!loadingTokenHistory && !isFetching && data?.data?.records?.length === 0 && (
        <EmptyCard description="No tokens to display!" />
      )}
      {generateToken && <GenerateToken handleModal={handleCloseModal} />}
    </div>
  );
};

export default TokenHistory;
